<template>
  <div class="evaluation" v-loading="submitLoading" element-loading-text="数据提交中" v-if="questionList && questionList[sort - 1]">
    <div class="header"></div>
    <div class="main">
      <div class="progressBox">
        <el-progress class="progress" :stroke-width="10" :percentage="percentage" :show-text="false" color="#73986D"></el-progress>
        <p class="sortBox">
          <span class="sort">{{ sort }}</span
          ><span class="quesLen">/{{ questionList.length }}</span>
        </p>
      </div>
      <div class="scrollBox">
        <div class="moduleName" v-if="questionList[sort - 1].moduleName">
          {{ questionList[sort - 1].moduleName }}
        </div>
        <div class="moduleDesc" v-if="questionList[sort - 1].moduleDesc">
          {{ questionList[sort - 1].moduleDesc }}
        </div>
        <div class="moduleQues" v-for="(item, index) in questionList[sort - 1].questionArray" :key="index">
          <b class="quesInfo"><span v-if="questionList[sort - 1].questionArray.length > 1" >{{ index + 1 }}.</span >&nbsp;{{ item.quesInfo }} <span v-if="item.least || item.most || item.shortest || item.longest">（<span v-if="item.least">至少{{item.least}}项</span> <span v-if="item.most">至多{{item.most}}项</span><span v-if="item.shortest">至少{{item.shortest}}字</span> <span v-if="item.longest">至多{{item.longest}}字</span>）</span></b>
          <img class="quesImg" style="width:50%;margin-bottom:20px;"  v-if="item.quesImg" :src="item.quesImg" alt="">
          <!-- 单项选择题 -->
          <div class="radio" v-if="item.answerType == 'A'">
            <ul>
              <li v-for="(each, idx) in item.optionArray" :key="idx">
                <div @click="groupRadioCheck(item, each)" :class="each.optionId == item.answer ? 'item active' : 'item'">
                  <p>{{ each.optionInfo }}</p>
                </div>
              </li>
            </ul>
          </div>
          <!-- 多选题 -->
          <div class="checkbox" v-if="item.answerType == 'C'">
            <ul>
              <li v-for="(each, idx) in item.optionArray" :class="each.checked ? 'active' : ''" :key="idx">
                <p>
                  <el-checkbox v-model="each.checked" @change="checkboxCheck(idx,item)">{{ each.optionInfo }}</el-checkbox>
                </p>
              </li>
            </ul>
          </div>
          <!-- 填空题 -->
          <div class="inputBox" v-if="item.answerType == 'B3'">
            <el-input v-model="item.answer" type="text" placeholder="请输入" @input="textInput"></el-input>
            <span class="unit">{{item.suffix}}</span>
          </div>
          <!-- 滑块题 -->
          <div class="slider" v-if="item.answerType == 'S'">
            <span class="leftDesc" v-if="item.leftDesc">{{item.leftDesc}}</span>
            <el-slider v-model="item.answer" :min="item.lowest" :max="item.bigest" :step="item.step" :marks="item.marks" @change="sliderChange(item)"></el-slider>
            <span class="rightDesc" v-if="item.rightDesc">{{item.rightDesc}}</span>
          </div>
          <!-- 下拉题 -->
          <div class="selectBox" v-if="item.answerType == 'A4'">
            <el-select v-model="item.answer" @change="selectChange($event,item)">
              <el-option v-for="each in item.optionArray" :key="each.optionId" :label="each.optionInfo" :value="each.optionId"></el-option>
            </el-select>
          </div>
          <!-- 文本题 -->
          <div class="textBox" v-if="item.answerType == 'B'">
            <el-input v-model="item.answer" type="textarea" placeholder="请输入" :minlength="item.shortest ? item.shortest : ''"  :maxlength="item.longest ? item.longest : ''"  @input="textInput" :autosize="{minRows:4}" show-word-limit></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btns">
        <span class="prev" @click="prev" v-if="sort > 1">上一题</span>
        <span class="next" @click="next" v-if="sort != questionList.length">下一题</span >
        <span v-if="sort == questionList.length" class="submit" @click="checkQues(false)">提交</span >
      </div>
    </div>
  </div>
</template>

<script>
import { getQuesDispense,saveObjectiveAns } from '@/api/index';
export default {
  data() {
    return {
      submitLoading:false,
      questionList: [],
      sort: 1,
      answerList: [],
      startTime: 0, //起始时间
      endTime: 0, //结束时间
    }
  },
  computed: {
    percentage() {
      return parseInt((this.sort / this.questionList.length) * 100);
    },
  },
  methods: {
    //获取问题
    getQuestion() {
      let query = {
        fmUserId: this.$util.getStorage('fmUserId'),
        quesNum:'0-1'
      }
      getQuesDispense(query).then((res) => {
        if (res.code === 0) {
        this.questionList = res.data.list;
          this.questionList.forEach(item => {
            item.questionArray.forEach(ques => {
              ques.answer = '';
              if(ques.answerType === 'S') {
                ques.marks = {};
                ques.answer = ques.optionArray[0].optionScore;
                ques.optionArray.forEach(opt => {
                  this.$set(ques.marks, [opt.optionScore], opt.optionInfo);
                });
              }
              if(ques.answerType === 'C') {
                ques.optionArray.forEach(opt => {
                  opt.checked = false;
                })
              }
            })
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
    //单选
    groupRadioCheck(item, each) {
      item.answer = each.optionId;
      item.optionId = each.optionId;
      item.optionScore = each.optionScore;
      this.$forceUpdate();
    },
    //多选
    checkboxCheck(idx,item) {
      this.$forceUpdate();
      let checkLen = item.optionArray.filter((item) => item.checked).length;
      if(checkLen > item.most) {
        this.$message({
          message: '至多'+ item.most +'项',
          type: 'warning',
        });
        item.optionArray[idx].checked = false;
        return false
      }
      item.answerArr = [];
      item.optionIdArr = [];
      item.optionScoreArr = [];
      item.optionArray.forEach((option) => {
        if (option.checked) {
          item.answerArr.push(option.optionId);
          item.optionIdArr.push(option.optionId);
          item.optionScoreArr.push(option.optionScore);
        }
      });
      item.answer = item.answerArr.join(',');
      item.optionId = item.optionIdArr.join(',');
      item.optionScore = item.optionScoreArr.join(',');
    },
    //滑块题
    sliderChange(item) {
      this.$forceUpdate();
      item.optionArray.forEach((option) => {
        if(option.optionScore == item.answer) {
          item.optionId = option.optionId;
          item.optionScore = option.optionScore;
        }
      })
    },
    //下拉题
    selectChange(event,item) {
      item.optionArray.forEach((option) => {
        if(option.optionId == event) {
          item.optionId = option.optionId;
          item.optionScore = option.optionScore;
        }
      })
      this.$forceUpdate();
    },
    //文本题
    textInput() {
      this.$forceUpdate();
    },
    //上一题
    prev() {
      if (this.sort <= 1) {
        return false;
      }
      this.sort--;
      this.$nextTick(()=> {
        document.querySelector('.evaluation').scrollTop = 0;
      })
    },
    //下一题
    next() {
      if (this.sort >= this.questionList.length) {
        return false;
      }
      this.checkQues(true);
    },
    //校验当前答题
    checkQues(flag) {
      //多选题目至少数量限制
      let leastLen = this.questionList[this.sort - 1].questionArray.filter(
        (item) => item.answerType =='C' && (item.answerArr.length < item.least)
      )
      if(leastLen.length > 0) {
        this.$message({
          message: '多选题已选数量不符合最低要求！',
          type: 'warning',
        });
        return false
      }
      //输入框至少长度限制
      let shortLen = this.questionList[this.sort - 1].questionArray.filter(
        (item) => item.answerType =='B' && (item.answer.length < item.shortest)
      )
      if(shortLen.length > 0) {
        this.$message({
          message: '文本题输入长度不符合最低要求！',
          type: 'warning',
        });
        return false
      }
      //所有题都必须有答案
      let noAnswer = this.questionList[this.sort - 1].questionArray.filter(
        (item) => item.answer == null || item.answer == ''
      );
      if (noAnswer.length > 0) {
        this.$message({
          message: '请完成当前问卷!',
          type: 'warning',
        });
        return false
      }
      if(flag) {
        this.sort++;
        this.$nextTick(()=> {
          document.querySelector('.scrollBox').scrollTop = 0;
        })
      } else {
        this.submit();
      }
    },
    //提交
    submit() {
      this.submitLoading = true;
      this.endTime = this.$util.getTimestamp();
      this.answerList = [];
      this.questionList.forEach((item) => {
        item.questionArray.forEach((each) => {
          if (each.answer) {
            this.answerList.push({
              fmQuesObjectiveId: each.fmQuesObjectiveId,
              moduleId: item.moduleId,
              answerType: each.answerType,
              answer: each.answer,
              optionId: each.optionId !== '' ? each.optionId : '',
              optionScore: each.optionScore !== ''  ? each.optionScore : '',
            });
          }
        });
      });
      let params = {
        fmUserId: this.$util.getStorage('fmUserId'), //用户ID
        totalTime: this.endTime - this.startTime,
        myAns: this.answerList,
        quesNum:'0-1'
      };
      saveObjectiveAns(params)
        .then((res) => {
          if (res.code ===0) {
            sessionStorage.setItem('fmProductQuestionId', res.data.fmProductQuestionId );
            //小题编号
            sessionStorage.setItem('quesNum', res.data.quesNum);
            //是否完成
            sessionStorage.setItem('isFinished', res.data.isFinished);
            if(res.data.isFinished == 1) {
              this.$router.replace({
                path: '/quesFinish',
                query: {
                  isFinished: res.data.isFinished,
                },
              });
            } else {
              if(res.data.quesNum == '0-1') {
                this.$router.replace('/ques');
              } else if(res.data.quesNum == '0-2') {
                this.$router.replace('/evaluation');
              } else {
                this.$router.replace('/monitor');
              }
            }
            this.submitLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.submitLoading = false;
        });
    }
  },
  created() {
    this.getQuestion();
  },
  mounted() {
    this.startTime = this.$util.getTimestamp();
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-slider__marks-text {
  white-space: nowrap;
}
@media screen and (max-width: 1900px) {
  .evaluation {
    font-family: 'SYHeiNormal';
    height: 100vh;
    background: #efefef;
    .header {
      height: 5vh;
    }
    .main {
      width: 960px;
      height: 80vh;
      background: #ffffff;
      border-radius: 16px;
      padding: 0 32px;
      margin: 0 auto;
      .progressBox {
        width: 900px;
        margin: 0 auto;
        display: flex;
        padding-top: 20px;
        margin-bottom: 20px;
        .progress {
          width: 832px;
          margin-right: 30px;
          padding-top: 10px;
        }
        .sortBox {
          text-align: center;
          display: inline-block;
          .sort {
            color: #73986d;
            font-size: 26px;
          }
          .quesLen {
            font-size: 14px;
          }
        }
      }
      .scrollBox {
        // padding-right: 20px;
        overflow-y: scroll;
        height: calc(100% - 100px);
        .moduleName {
          line-height: 22px;
          font-size: 17px;
          font-weight: 550;
          margin-bottom: 15px;
          white-space: pre-line;
        }
        .moduleDesc {
          color: #666666;
          line-height: 22px;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 15px;
          white-space: pre-line;
        }
        .moduleQues {
          margin-bottom: 28px;
          .quesInfo {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 16px;
            display: block;
            font-weight: 400;
          }
          .ladder {
            text-align: center;
            img {
              width: 300px;
              height: 212px;
            }
          }
          .inputBox {
            ::v-deep .el-input {
              width: 230px;
            }
            ::v-deep input[type='number'] {
              border-radius: 17px;
              text-align: center;
            }
            .unit {
              display: inline-block;
              color: #666666;
              font-size: 13px;
              margin-left: 12px;
            }
          }
          .radio {
            font-size: 13px;
            li {
              display: inline-block;
              margin-right: 10px;
              vertical-align: top;
              .item {
                min-height: 34px;
                padding: 8px 55px;
                line-height: 17px;
                margin-bottom: 8px;
                color: #73986d;
                background: #edf3ed;
                cursor: pointer;
                text-align: center;
                border-radius: 17px;
              }
              li:last-child {
                margin-right: 0;
              }
              .active {
                color: #ffffff;
                background: #73986d;
              }
            }
          }
          .checkbox {
            font-size: 13px;
            li {
              vertical-align: top;
              min-height: 34px;
              line-height: 17px;
              border-radius: 17px;
              margin: 0 15px 10px auto;
              color: #73986d;
              background: #edf3ed;
              cursor: pointer;
              text-align: left;
              display: inline-block;
              padding: 8px 50px 8px 20px;
            }
            li:last-child {
              margin-right: 0;
            }
            ::v-deep .el-checkbox__label {
              color: #73986d;
              display: inline-grid;
              white-space: pre-line;
              word-wrap: break-word;
              overflow: hidden;
              line-height: 17px;
              font-size: 13px;
            }
            .active {
              color: #ffffff;
              background: #73986d;
              ::v-deep .el-checkbox__label {
                color: #ffffff;
              }
            }
          }
          .slider {
            padding: 20px 60px;
            position: relative;
            color: #909399;
            .leftDesc {
              display: inline-block;
              position: absolute;
              left: 0;
              top: 0;
            }
            .rightDesc {
              display: inline-block;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
        }
      }
    }
    .bottom {
      height: 15vh;
      margin: 0 auto;
      overflow: hidden;
      text-align: center;
      .btns {
        font-size: 13px;
        margin-top: 30px;
        .prev,
        .next,
        .submit {
          width: 114px;
          height: 34px;
          line-height: 34px;
          background: #73986d;
          border-radius: 17px;
          display: inline-block;
          cursor: pointer;
        }
        .prev {
          color: #73986d;
          background: #ffffff;
          margin-right: 32px;
        }
        .next,
        .submit {
          color: #ffffff;
          background: #73986d;
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .evaluation {
    font-family: 'SYHeiNormal';
    height: 100vh;
    background: #efefef;
    .header {
      height: 5vh;
    }
    .main {
      width: 1248px;
      height: 80vh;
      background: #ffffff;
      border-radius: 21px;
      padding: 0 41px;
      margin: 0 auto;
      .progressBox {
        width: 1170px;
        margin: 0 auto;
        display: flex;
        padding-top: 26px;
        margin-bottom: 26px;
        .progress {
          width: 1082px;
          margin-right: 30px;
          padding-top: 13px;
        }
        .sortBox {
          text-align: center;
          display: inline-block;
          .sort {
            color: #73986d;
            font-size: 34px;
          }
          .quesLen {
            font-size: 18px;
          }
        }
      }
      .scrollBox {
        padding-right: 26px;
        overflow-y: scroll;
        height: calc(100% - 130px);
        .moduleName {
          line-height: 29px;
          font-size: 23px;
          font-weight: 550;
          margin-bottom: 20px;
          white-space: pre-line;
        }
        .moduleDesc {
          color: #666666;
          line-height: 29px;
          font-size: 21px;
          font-weight: 500;
          margin-bottom: 20px;
          white-space: pre-line;
        }
        .moduleQues {
          margin-bottom: 36px;
          .quesInfo {
            font-size: 21px;
            line-height: 26px;
            margin-bottom: 21px;
            display: block;
            font-weight: 400;
          }
          .ladder {
            text-align: center;
            img {
              width: 390px;
              height: 275px;
            }
          }
          .inputBox {
            ::v-deep .el-input {
              width: 299px;
            }
            ::v-deep input[type='number'] {
              border-radius: 22px;
              text-align: center;
            }
            .unit {
              display: inline-block;
              color: #666666;
              font-size: 17px;
              margin-left: 15px;
            }
          }
          .radio {
            font-size: 17px;
            li {
              vertical-align: top;
              display: inline-block;
              margin-right: 13px;
              .item {
                min-height: 44px;
                padding: 11px 70px;
                line-height: 22px;
                margin-bottom: 11px;
                color: #73986d;
                background: #edf3ed;
                cursor: pointer;
                text-align: center;
                border-radius: 22px;
              }
              li:last-child {
                margin-right: 0;
              }
              .active {
                color: #ffffff;
                background: #73986d;
              }
            }
          }
          .checkbox {
            font-size: 17px;
            li {
              vertical-align: top;
              min-height: 44px;
              line-height: 22px;
              border-radius: 22px;
              margin: 0 20px 13px auto;
              color: #73986d;
              background: #edf3ed;
              cursor: pointer;
              text-align: left;
              display: inline-block;
              padding: 11px 65px 11px 26px;
            }
            li:last-child {
              margin-right: 0;
            }
            ::v-deep .el-checkbox__label {
              color: #73986d;
              display: inline-grid;
              white-space: pre-line;
              word-wrap: break-word;
              overflow: hidden;
              line-height: 22px;
              font-size: 17px;
            }
            .active {
              color: #ffffff;
              background: #73986d;
              ::v-deep .el-checkbox__label {
                color: #ffffff;
              }
            }
          }
          .slider {
            padding: 26px 75px;
            position: relative;
            color: #909399;
            .leftDesc {
              display: inline-block;
              position: absolute;
              left: 0;
              top: 0;
            }
            .rightDesc {
              display: inline-block;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
        }
      }
    }
    .bottom {
      height: 15vh;
      margin: 0 auto;
      overflow: hidden;
      text-align: center;
      .btns {
        font-size: 17px;
        margin-top: 39px;
        .prev,
        .next,
        .submit {
          width: 148px;
          height: 44px;
          line-height: 44px;
          background: #73986d;
          border-radius: 22px;
          display: inline-block;
          cursor: pointer;
        }
        .prev {
          color: #73986d;
          background: #ffffff;
          margin-right: 41px;
        }
        .next,
        .submit {
          color: #ffffff;
          background: #73986d;
        }
      }
    }
  }
}

</style>
